import oFetch from 'o-fetch';
import queryString from 'query-string';
import utils from '@/lib/utils';
import format from 'date-fns/format';
import { UI_DATE_FORMAT } from './date-fns-formats';

import { Methods } from './api-routes';
export const GET_METHOD = Methods.GET_METHOD;
export const PATCH_METHOD = Methods.PATCH_METHOD;
export const POST_METHOD = Methods.POST_METHOD;
export const DELETE_METHOD = Methods.DELETE_METHOD;
export const PUT_METHOD = Methods.PUT_METHOD;

var staffMemberPaymentsAppPath = (staffMemberId, queryStringParams) => {
  if (staffMemberId === undefined) {
    throw new Error('No staff member id supplied to appRoutes.staffMember');
  }
  const baseUrl = `/staff_members/${staffMemberId}/payments`;

  if (typeof queryStringParams === undefined || _.isEmpty(queryStringParams)) {
    return baseUrl;
  } else {
    let queryString = '?';
    let paramIndex = 0;
    for (const key in queryStringParams) {
      queryString = `${queryString}${paramIndex > 0 ? '&' : ''}${key}=${queryStringParams[key]}`;
      paramIndex = paramIndex + 1;
    }
    return baseUrl + queryString;
  }
};

// Shared Helpers
var staffMemberProfileHolidaysTabPath = function (params) {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const mStartDate = params.mStartDate;
  const mEndDate = params.mEndDate;
  const mPayslipStartDate = params.mPayslipStartDate;
  const mPayslipEndDate = params.mPayslipEndDate;
  const filteringByDate = mStartDate && mEndDate;
  const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

  let result = '/staff_members/' + staffMemberId + '/holidays';
  if (filteringByDate || filteringByPayslipDate) {
    result = result + '?';
    if (filteringByDate) {
      result =
        result +
        'start_date=' +
        mStartDate.format(utils.apiDateFormat) +
        '&end_date=' +
        mEndDate.format(utils.apiDateFormat);
    }
    if (filteringByPayslipDate) {
      if (filteringByDate) {
        result = result + '&';
      }
      result =
        result +
        'payslip_start_date=' +
        mPayslipStartDate.format(utils.apiDateFormat) +
        '&payslip_end_date=' +
        mPayslipEndDate.format(utils.apiDateFormat);
    }
  }
  return result;
};

var mossStaffMemberProfileHolidaysTabPath = function (params) {
  const mossStaffMemberId = oFetch(params, 'mossStaffMemberId');
  const mStartDate = params.mStartDate;
  const mEndDate = params.mEndDate;
  const mPayslipStartDate = params.mPayslipStartDate;
  const mPayslipEndDate = params.mPayslipEndDate;
  const filteringByDate = mStartDate && mEndDate;
  const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

  let result = '/moss_staff_members/' + mossStaffMemberId + '/holidays';
  if (filteringByDate || filteringByPayslipDate) {
    result = result + '?';
    if (filteringByDate) {
      result =
        result +
        'start_date=' +
        mStartDate.format(utils.apiDateFormat) +
        '&end_date=' +
        mEndDate.format(utils.apiDateFormat);
    }
    if (filteringByPayslipDate) {
      if (filteringByDate) {
        result = result + '&';
      }
      result =
        result +
        'payslip_start_date=' +
        mPayslipStartDate.format(utils.apiDateFormat) +
        '&payslip_end_date=' +
        mPayslipEndDate.format(utils.apiDateFormat);
    }
  }
  return result;
};

export const appRoutes = {
  bonaiPage(options) {
    const uiStartDate = oFetch(options, 'uiStartDate');
    return `/bonai?week_start=${uiStartDate}`;
  },
  handoverPlannersMonthPrev(params) {
    const [mDate, venueId] = oFetch(params, 'mDate', 'venueId');
    const prevMonth = mDate.clone().subtract(1, 'month');
    const shortMonth = prevMonth.format('MMM').toLowerCase();
    const year = prevMonth.year();
    const query = queryString.stringify({ venue_id: venueId, month: shortMonth, year });

    return `/handover_planners?${query}`;
  },
  handoverPlannersMonthNext(params) {
    const [mDate, venueId] = oFetch(params, 'mDate', 'venueId');
    const nextMonth = mDate.clone().add(1, 'month');
    const shortMonth = nextMonth.format('MMM').toLowerCase();
    const year = nextMonth.year();
    const query = queryString.stringify({ venue_id: venueId, month: shortMonth, year });
    return `/handover_planners?${query}`;
  },
  handoverPlannersWeekPrev(params) {
    const [mDate, venueId] = oFetch(params, 'mDate', 'venueId');
    const prevWeek = mDate.clone().subtract(1, 'week');
    const formattedDate = prevWeek.format(utils.commonDateFormat);
    const query = queryString.stringify({ venue_id: venueId });
    return `/handover_planners/${formattedDate}?${query}`;
  },
  handoverPlannersWeekNext(params) {
    const [mDate, venueId] = oFetch(params, 'mDate', 'venueId');
    const nextWeek = mDate.clone().add(1, 'week');
    const formattedDate = nextWeek.format(utils.commonDateFormat);
    const query = queryString.stringify({ venue_id: venueId });
    return `/handover_planners/${formattedDate}?${query}`;
  },
  handoverPlannersMonthView(params) {
    const [mDate, venueId] = oFetch(params, 'mDate', 'venueId');
    const shortMonth = mDate.format('MMM').toLowerCase();
    const year = mDate.year();
    const query = queryString.stringify({ venue_id: venueId, month: shortMonth, year });
    return `/handover_planners?${query}`;
  },
  handoverPlannersWeekView(params) {
    const [mDate, venueId] = oFetch(params, 'mDate', 'venueId');
    const formattedDate = mDate.format(utils.commonDateFormat);
    const query = queryString.stringify({ venue_id: venueId });
    return `/handover_planners/${formattedDate}?${query}`;
  },
  staffMemberProfile(staffMemberId) {
    return `/staff_members/${staffMemberId}`;
  },
  staffMemberHistory({ staffMemberId, startsAt, endsAt }) {
    const urlQuery = queryString.stringify({
      starts_at: startsAt || undefined,
      ends_at: endsAt || undefined,
    });
    return `/staff_members/${staffMemberId}/history?${urlQuery}`;
  },
  staffMemberCreateHoliday: (args) => {
    const staffMemberId = oFetch(args, 'staffMemberId');
    return `/api/v1/staff_members/${staffMemberId}/create_holiday`;
  },
  mossStaffMemberCreateHoliday: (args) => {
    const mossStaffMemberId = oFetch(args, 'mossStaffMemberId');
    return `/api/v1/moss_staff_members/${mossStaffMemberId}/create_holiday`;
  },
  staffMemberCreateHolidayOnHolidaysPage: (args) => {
    const staffMemberId = oFetch(args, 'staffMemberId');
    return `/api/v1/staff_members/${staffMemberId}/create_holiday_on_holiday_page`;
  },
  mossStaffMemberCreateHolidayOnHolidaysPage: (args) => {
    const mossStaffMemberId = oFetch(args, 'mossStaffMemberId');
    return `/api/v1/moss_staff_members/${mossStaffMemberId}/create_holiday_on_holiday_page`;
  },
  securityRota: function (options) {
    var [venueId, date] = oFetch(options, 'venueId', 'date');
    return '/security_rotas/' + utils.formatRotaUrlDate(date) + '?venue_id=' + venueId;
  },
  rota: function (options) {
    var [venueId, date] = oFetch(options, 'venueId', 'date');
    return '/rotas/' + utils.formatRotaUrlDate(date) + '?venue_id=' + venueId;
  },
  rotaPdfDownload: function (options) {
    var [venueId, startDate, endDate] = oFetch(options, 'venueId', 'startDate', 'endDate');
    return [
      '/rotas.pdf?',
      'start_date=' + utils.formatRotaUrlDate(startDate),
      '&end_date=' + utils.formatRotaUrlDate(endDate),
      '&venue_id=' + venueId,
    ].join('');
  },
  rotaOverviewPdfDownload: function (options) {
    const [mHighlightDate, venueId] = oFetch(options, 'mHighlightDate', 'venueId');
    return [
      'rotas.pdf?',
      'highlight_date=' + mHighlightDate.format(utils.apiDateFormat),
      '&venue_id=' + venueId,
    ].join('');
  },
  staffVettingIndex: function () {
    return '/staff_vetting';
  },
  userProfile(userId) {
    return `/users/${userId}`;
  },
  securityRotaPdfDownload: function (options) {
    var date = oFetch(options, 'date');
    return ['/security_rotas.pdf?', 'date=' + date].join('');
  },
  securityRotaTxtDownload: function (options) {
    var sDate = oFetch(options, 'sDate');
    return ['/security_rotas.txt?', 'date=' + sDate].join('');
  },
  financeReportsPdfDownload: function (options) {
    var date = oFetch(options, 'date');
    var venueId = oFetch(options, 'venueId');
    var filterType = oFetch(options, 'filterType');
    return `/finance_reports/${utils.formatRotaUrlDate(
      date,
    )}.pdf?venue_id=${venueId}&filter_type=${filterType}`;
  },
  financeReportsPaymentCSVExport: function (options) {
    var date = oFetch(options, 'date');
    var venueId = oFetch(options, 'venueId');
    var filterType = oFetch(options, 'filterType');
    return `/finance_reports/${utils.formatRotaUrlDate(
      date,
    )}.csv?venue_id=${venueId}&filter_type=${filterType}&csv_type=payments`;
  },
  financeReportsDeductionCSVExport: function (options) {
    var date = oFetch(options, 'date');
    var venueId = oFetch(options, 'venueId');
    var filterType = oFetch(options, 'filterType');
    return `/finance_reports/${utils.formatRotaUrlDate(
      date,
    )}.csv?venue_id=${venueId}&filter_type=${filterType}&csv_type=deduction`;
  },
  venueBulkPaymentsDownload: function (options) {
    var mWeekStartDate = oFetch(options, 'mWeekStartDate');
    var venueId = oFetch(options, 'venueId');
    return `/finance_reports/${mWeekStartDate.format(
      utils.apiDateFormat,
    )}/venue_bulk_payment?venue_id=${venueId}`;
  },
  modulrPayrollCompanyBulkPaymentsDownload: function (options) {
    const mWeekStartDate = oFetch(options, 'mWeekStartDate');
    const modulrPayrollCompanyId = oFetch(options, 'modulrPayrollCompanyId');
    return `/modulr_reports/${mWeekStartDate.format(
      utils.apiDateFormat,
    )}/bulk_payment?modulr_payroll_company_id=${modulrPayrollCompanyId}`;
  },
  mossFinanceReportsPdfDownload: function (options) {
    var date = oFetch(options, 'date');
    var venueId = oFetch(options, 'venueId');
    var filterType = oFetch(options, 'filterType');
    return `/moss_finance_reports/${utils.formatRotaUrlDate(
      date,
    )}.pdf?venue_id=${venueId}&filter_type=${filterType}`;
  },
  payrollReportsPdfDownload: function (options) {
    var date = oFetch(options, 'date');
    var venueId = oFetch(options, 'venueId');
    var filterType = oFetch(options, 'filterType');
    return `/payroll_reports/${utils.formatRotaUrlDate(
      date,
    )}.pdf?venue_id=${venueId}&filter_type=${filterType}`;
  },
  securityRotaOverview: options => {
    const sStartDate = oFetch(options, 'sStartDate');
    return `/security_rotas?highlight_date=${sStartDate}`;
  },
  securityShiftRequestReviews: options => {
    const startDate = oFetch(options, 'startDate');
    return `/security-shift-request-reviews/${startDate}`;
  },
  securityShiftRequests: options => {
    const startDate = oFetch(options, 'startDate');
    return `/security-shift-requests/${startDate}`;
  },
  securityRotaShiftRequests: options => {
    const mStartDate = oFetch(options, 'mStartDate');
    return `/security_rotas/${mStartDate.format(utils.apiDateFormat)}/requests`;
  },
  securityRotaDaily: args => {
    return `/security_rotas/${oFetch(args, 'sDate')}`;
  },
  staffMemberProfileShifts({ startDate, endDate, staffMemberId }) {
    if (!staffMemberId) {
      throw new Error(`You must apply a staffMemberId`);
    }
    if (startDate && endDate) {
      return `/staff_members/${staffMemberId}/shifts?end_date=${endDate}&start_date=${startDate}`;
    }
    return `/staff_members/${staffMemberId}/shifts`;
  },
  staffMemberProfileHolidays({ startDate, endDate, staffMemberId }) {
    if (!staffMemberId) {
      throw new Error(`You must apply a staffMemberId`);
    }
    if (startDate && endDate) {
      return `/staff_members/${staffMemberId}/holidays?end_date=${endDate}&start_date=${startDate}`;
    }
    return `/staff_members/${staffMemberId}/holidays`;
  },
  rotaDaily: (date, venueId) => {
    return `/rotas/${date}${venueId ? `?venue_id=${venueId}` : ''}`;
  },
  financeReports: options => {
    const startDate = oFetch(options, 'startDate');
    const venueId = oFetch(options, 'venueId');
    return `/finance_reports/${startDate}?venue_id=${venueId}`;
  },
  mossFinanceReports: options => {
    const startDate = oFetch(options, 'startDate');
    const venueId = oFetch(options, 'venueId');
    return `/moss_finance_reports/${startDate}?venue_id=${venueId}`;
  },
  financeReportsScrollTo: options => {
    const startDate = oFetch(options, 'startDate');
    const venueId = oFetch(options, 'venueId');
    const financeReportId = oFetch(options, 'financeReportId');
    return `/finance_reports/${startDate}?venue_id=${venueId}#scroll_to=${financeReportId}`;
  },
  modulrReports: options => {
    const startDate = oFetch(options, 'startDate');
    return `/modulr_reports/${startDate}`;
  },
  payrollReports: options => {
    const startDate = oFetch(options, 'startDate');
    const venueId = oFetch(options, 'venueId');
    return `/payroll_reports/${startDate}?venue_id=${venueId}`;
  },
  securityPayrollReports: options => {
    const mWeekStartDate = oFetch(options, 'mWeekStartDate');
    return `/security_payroll_reports?week_start=${mWeekStartDate.format(utils.uiRotaDateFormat)}`;
  },
  securityPayrollReportsCsv: options => {
    const mWeekStartDate = oFetch(options, 'mWeekStartDate');
    return `/security_payroll_reports.csv?week_start=${mWeekStartDate.format(utils.uiRotaDateFormat)}`;
  },
  rotaOverview: function (options) {
    var [venueId, startDate] = oFetch(options, 'venueId', 'startDate');
    return ['/rotas?venue_id=' + venueId + '&highlight_date=' + startDate].join('');
  },
  changeOrdersIndex: function (options) {
    const date = oFetch(options, 'date');
    const venueId = oFetch(options, 'venueId');

    return ['change_orders?', 'date=' + utils.formatRotaUrlDate(date), '&venue_id=' + venueId].join('');
  },
  changeOrderReportsIndex: function (options) {
    const date = oFetch(options, 'date');
    return 'change_order_reports?date=' + utils.formatRotaUrlDate(date);
  },
  venueHealthChecksIndex: function () {
    return `/venue_health_check`;
  },
  holidays: function (options) {
    var sStartDate = oFetch(options, 'sStartDate');
    var venueId = options.venueId; // venueId is optional

    var parts = ['holidays?', 'date=' + sStartDate];
    if (typeof venueId !== 'undefined' && venueId !== null) {
      parts.push('&venue=' + venueId);
    }
    return parts.join('');
  },
  mossHolidays: function (options) {
    var sStartDate = oFetch(options, 'sStartDate');
    var venueId = options.venueId; // venueId is optional

    var parts = ['moss_holidays?', 'date=' + sStartDate];
    if (typeof venueId !== 'undefined' && venueId !== null) {
      parts.push('&venue=' + venueId);
    }
    return parts.join('');
  },
  staffMember: function (staffMemberId) {
    if (staffMemberId === undefined) {
      throw new Error('No staff member id supplied to appRoutes.staffMember');
    }
    return '/staff_members/' + staffMemberId;
  },
  mossStaffMember: function (mossStaffMemberId) {
    if (mossStaffMemberId === undefined) {
      throw new Error('No moss staff member id supplied to appRoutes.mossStaffMember');
    }
    return '/moss_staff_members/' + mossStaffMemberId;
  },
  staffMembersIndex: function (params = {}) {
    const { nameText, emailText, status, venueId, staffType } = params;
    const baseURL = '/staff_members';
    if (nameText || emailText || status || venueId || staffType) {
      let queryString = '/?';
      if (nameText) {
        queryString = queryString + `name_text=${nameText}`;
      }
      if (emailText) {
        queryString = queryString + `email_text=${emailText}`;
      }
      if (status) {
        queryString = queryString + `status=${status}`;
      }
      if (venueId) {
        queryString = queryString + `venue=${venueId}`;
      }
      if (staffType) {
        queryString = queryString + `staff_type=${staffType}`;
      }
      return baseURL + queryString;
    }
    return baseURL;
  },
  mossStaffMemberProfile: args => {
    const mossStaffMemberId = oFetch(args, 'mossStaffMemberId');
    return `/moss_staff_members/${mossStaffMemberId}`;
  },
  mossStaffMemberProfileHolidaysTab: mossStaffMemberProfileHolidaysTabPath,
  staffMemberProfileHolidaysTab: staffMemberProfileHolidaysTabPath,
  staffMemberProfileHolidaysTabFromFinanceReport: function (params) {
    const staffMemberId = oFetch(params, 'staffMemberId');
    const mPayslipStartDate = oFetch(params, 'mPayslipStartDate');
    const mPayslipEndDate = oFetch(params, 'mPayslipEndDate');

    return staffMemberProfileHolidaysTabPath({
      staffMemberId: staffMemberId,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
  },
  staffMemberOwedHours: function (params) {
    const staffMemberId = oFetch(params, 'staffMemberId');
    const mStartDate = params.mStartDate;
    const mEndDate = params.mEndDate;
    const mPayslipStartDate = params.mPayslipStartDate;
    const mPayslipEndDate = params.mPayslipEndDate;
    const filteringByDate = mStartDate && mEndDate;
    const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

    let result = '/staff_members/' + staffMemberId + '/owed_hours';
    if (filteringByDate || filteringByPayslipDate) {
      result = result + '?';
      if (filteringByDate) {
        result =
          result +
          'start_date=' +
          mStartDate.format(utils.apiDateFormat) +
          '&end_date=' +
          mEndDate.format(utils.apiDateFormat);
      }
      if (filteringByPayslipDate) {
        if (filteringByDate) {
          result = result + '&';
        }
        result =
          result +
          'payslip_start_date=' +
          mPayslipStartDate.format(utils.apiDateFormat) +
          '&payslip_end_date=' +
          mPayslipEndDate.format(utils.apiDateFormat);
      }
    }
    return result;
  },
  mossStaffMemberOwedHours: function (params) {
    const mossStaffMemberId = oFetch(params, 'mossStaffMemberId');
    const mStartDate = params.mStartDate;
    const mEndDate = params.mEndDate;
    const mPayslipStartDate = params.mPayslipStartDate;
    const mPayslipEndDate = params.mPayslipEndDate;
    const filteringByDate = mStartDate && mEndDate;
    const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

    let result = '/moss_staff_members/' + mossStaffMemberId + '/owed_hours';
    if (filteringByDate || filteringByPayslipDate) {
      result = result + '?';
      if (filteringByDate) {
        result =
          result +
          'start_date=' +
          mStartDate.format(utils.apiDateFormat) +
          '&end_date=' +
          mEndDate.format(utils.apiDateFormat);
      }
      if (filteringByPayslipDate) {
        if (filteringByDate) {
          result = result + '&';
        }
        result =
          result +
          'payslip_start_date=' +
          mPayslipStartDate.format(utils.apiDateFormat) +
          '&payslip_end_date=' +
          mPayslipEndDate.format(utils.apiDateFormat);
      }
    }
    return result;
  },
  staffMemberAccessories: function (params) {
    const staffMemberId = oFetch(params, 'staffMemberId');
    const { mPayslipStartDate, mPayslipEndDate } = params;
    const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;
    let result = `/staff_members/${staffMemberId}/accessories`;
    if (filteringByPayslipDate) {
      result =
        result +
        '?' +
        'payslip_start_date=' +
        mPayslipStartDate.format(utils.apiDateFormat) +
        '&payslip_end_date=' +
        mPayslipEndDate.format(utils.apiDateFormat);
    }
    return result;
  },
  staffMemberHoursOverview: function (args) {
    const staffMemberId = oFetch(args, 'staffMemberId');
    const dDate = oFetch(args, 'dDate');
    const uiFormattedDate = format(dDate, UI_DATE_FORMAT);

    return `/staff_members/${staffMemberId}/hours_overview/${uiFormattedDate}`;
  },
  mossStaffMemberHoursOverview: function (args) {
    const mossStaffMemberId = oFetch(args, 'mossStaffMemberId');
    const dDate = oFetch(args, 'dDate');

    return `/moss_staff_members/${mossStaffMemberId}/hours_overview/${format(dDate, UI_DATE_FORMAT)}`;
  },
  staffMemberPayments: staffMemberPaymentsAppPath,
  staffMemberPaymentHighlight: function (params) {
    const staffMemberId = oFetch(params, 'staffMemberId');
    const mStartDate = oFetch(params, 'mStartDate');
    const mEndDate = oFetch(params, 'mEndDate');

    return staffMemberPaymentsAppPath(staffMemberId, {
      start_date: mStartDate.format(utils.apiDateFormat),
      end_date: mEndDate.format(utils.apiDateFormat),
    });
  },
  holidaysCsv: function (options) {
    var sDate = oFetch(options, 'sDate');
    var venueId = options.venueId; // optional
    var parts = ['/holidays.csv?' + 'date=' + sDate];
    if (venueId !== null && venueId !== undefined) {
      parts.push('&venue=' + venueId);
    }
    return parts.join('');
  },
  mossHolidaysCsv: function (options) {
    var sDate = oFetch(options, 'sDate');
    var venueId = options.venueId; // optional
    var parts = ['/moss_holidays.csv?' + 'date=' + sDate];
    if (venueId !== null && venueId !== undefined) {
      parts.push('&venue=' + venueId);
    }
    return parts.join('');
  },
  staffTypeRota: function (options) {
    var [staffTypeSlug, dateOfRota] = oFetch(options, 'staffTypeSlug', 'dateOfRota');
    return '/' + staffTypeSlug + '_rotas/' + utils.formatRotaUrlDate(dateOfRota);
  },
  staffTypeRotaOverview: function (options) {
    var [staffTypeSlug, weekStartDate] = oFetch(options, 'staffTypeSlug', 'weekStartDate');
    return '/' + staffTypeSlug + '_rotas/?date=' + utils.formatRotaUrlDate(weekStartDate);
  },
  hoursConfirmationCurrentPage: function (options) {
    var venueId = oFetch(options, 'venueId');
    return '/hours_confirmation/current?venue_id=' + venueId;
  },
  mossHoursConfirmationCurrentPage: function (options) {
    var venueId = oFetch(options, 'venueId');
    return '/moss_hours_confirmation/current?venue_id=' + venueId;
  },
  hoursConfirmationDayPage: function (options) {
    const [date, venueId] = oFetch(options, 'date', 'venueId');
    return '/hours_confirmation?date=' + date.format(utils.commonDateFormat) + '&venue_id=' + venueId;
  },
  mossHoursConfirmationDayPage: function (options) {
    const [date, venueId] = oFetch(options, 'date', 'venueId');
    return '/moss_hours_confirmation?date=' + date.format(utils.commonDateFormat) + '&venue_id=' + venueId;
  },
  checklistsPage: function (options) {
    const venueId = oFetch(options, 'venueId');
    return '/check_lists?venue_id=' + venueId;
  },
  checklistSubmissionsPage: function (options) {
    const venueId = oFetch(options, 'venueId');
    return '/checklist_submissions?venue_id=' + venueId;
  },
  wtlCardsPage: function (options = {}) {
    const { cardNumber } = options;
    return `/wtl_cards${cardNumber ? `?card_number=${cardNumber}` : ''}`;
  },
};

const apiRoutes = {
  forceJsmSync: {
    getPath(args)  {
      const userId = oFetch(args, 'userId');
      return `/api/v1/users/${userId}/force_jsm_sync`;
    },
    method: POST_METHOD,
  },
  testWhatsappService(baseUrl) {
    return {
      getPath() {
        return baseUrl;
      },
      method: POST_METHOD,
    };
  },
  createAevaMessageSchedule: {
    getPath() {
      return `/api/v1/aeva/message_schedule`;
    },
    method: POST_METHOD,
  },
  updateAevaMessageSchedule: {
    getPath(messageScheduleId) {
      return `/api/v1/aeva/message_schedule/${messageScheduleId}`;
    },
    method: PUT_METHOD,
  },
  disableAevaMessageSchedule: {
    getPath(id) {
      return `/api/v1/aeva/message_schedule/${id}`;
    },
    method: DELETE_METHOD,
  },
  markRepeatOffender: {
    getPath() {
      return `/api/v1/staff_vetting/mark-repeat-offender`;
    },
    method: POST_METHOD,
  },
  createNonStaffMemberStaffPartyPerson: {
    getPath() {
      return '/api/v1/non_staff_member_staff_party_people';
    },
    method: Methods.POST_METHOD,
  },
  addHolidayStaffMemberOptions: {
    getPath: function ({ query, venueId }) {
      const urlQuery = queryString.stringify({ query, venue_id: venueId });
      return `/api/v1/staff_members/add_holiday_staff_member_options?${urlQuery}`;
    },
    method: GET_METHOD,
  },
  addHolidayMossStaffMemberOptions: {
    getPath: function ({ query, venueId }) {
      const urlQuery = queryString.stringify({ query, venue_id: venueId });
      return `/api/v1/moss_staff_members/add_holiday_staff_member_options?${urlQuery}`;
    },
    method: GET_METHOD,
  },
  markRetakeAvatar: {
    getPath(staffMemberId) {
      return `/api/v1/staff_members/${staffMemberId}/mark_retake_avatar`;
    },
    method: POST_METHOD,
  },
  mossMarkRetakeAvatar: {
    getPath(staffMemberId) {
      return `/api/v1/moss_staff_members/${staffMemberId}/mark_retake_avatar`;
    },
    method: POST_METHOD,
  },
  changeMasterVenue: {
    getPath(staffMemberId) {
      return `/api/v1/staff_members/${staffMemberId}/change_master_venue`;
    },
    method: POST_METHOD,
  },
  changeMossMasterVenue: {
    getPath(staffMemberId) {
      return `/api/v1/moss_staff_members/${staffMemberId}/change_master_venue`;
    },
    method: POST_METHOD,
  },
  dashboardMessages: {
    getPath: function () {
      return '/api/v1/dashboard_messages';
    },
    method: GET_METHOD,
  },
  disableDashboardMessages: {
    getPath: function (dashboardMessage) {
      return `/api/v1/dashboard_messages/${dashboardMessage}/disable`;
    },
    method: PUT_METHOD,
  },
  restoreDashboardMessages: {
    getPath: function (dashboardMessage) {
      return `/api/v1/dashboard_messages/${dashboardMessage}/restore`;
    },
    method: PUT_METHOD,
  },
  maintenanceTaskImageUpload: {
    getPath: function () {
      return '/api/v1/maintenance_task_image_uploads';
    },
    method: POST_METHOD,
  },
  maintenanceTasks: {
    getPath: function () {
      return '/api/v1/maintenance_tasks';
    },
    method: GET_METHOD,
  },
  maintenanceTaskChangeStatus: {
    getPath: function (maintenanceTaskId) {
      return `/api/v1/maintenance_tasks/${maintenanceTaskId}/change_status`;
    },
    method: POST_METHOD,
  },
  maintenanceTaskRepublishMessage: {
    getPath: function (maintenanceTaskId) {
      return `/api/v1/maintenance_tasks/${maintenanceTaskId}/republish_emergency_task_message`;
    },
    method: POST_METHOD,
  },
  maintenanceTaskNote: {
    getPath: function (maintenanceTaskId) {
      return `/api/v1/maintenance_tasks/${maintenanceTaskId}/add_note`;
    },
    method: POST_METHOD,
  },
  createMaintenanceTask: {
    getPath: function () {
      return '/api/v1/maintenance_tasks';
    },
    method: POST_METHOD,
  },
  deleteMaintenanceTask: {
    getPath: function (maintenanceTaskId) {
      return `/api/v1/maintenance_tasks/${maintenanceTaskId}`;
    },
    method: DELETE_METHOD,
  },
  deleteMaintenanceTaskImage: {
    getPath: function (maintenanceTaskImageId) {
      return `/api/v1/maintenance_task_image_uploads/${maintenanceTaskImageId}`;
    },
    method: DELETE_METHOD,
  },
  updateMaintenanceTask: {
    getPath: function (maintenanceTaskId) {
      return `/api/v1/maintenance_tasks/${maintenanceTaskId}`;
    },
    method: PUT_METHOD,
  },
  marketingTasksIndex: {
    getPath: function () {
      return '/api/v1/marketing_tasks';
    },
    method: GET_METHOD,
  },
  marketingTaskChangeStatus: {
    getPath: function (marketingTaskId) {
      return `/api/v1/marketing_tasks/${marketingTaskId}/change_status`;
    },
    method: PUT_METHOD,
  },
  marketingTaskNote: {
    getPath: function (marketingTaskId) {
      return `/api/v1/marketing_tasks/${marketingTaskId}/notes`;
    },
    method: POST_METHOD,
  },
  loadMoreMarketingTasks: {
    getPath: function (params) {
      const filterParams = oFetch(params, 'filterParams');
      const marketingTaskType = oFetch(params, 'marketingTaskType');
      const page = oFetch(params, 'page');
      const formattedQueryParams = `page=${page}&marketingTaskType=${marketingTaskType}&${$.param({
        filter: filterParams,
      })}`;

      return `/api/v1/marketing_tasks/load_more_tasks?${formattedQueryParams}`;
    },
    method: GET_METHOD,
  },
  createMarketingTask: {
    getPath: function () {
      return '/api/v1/marketing_tasks';
    },
    method: POST_METHOD,
  },
  deleteMarketingTask: {
    getPath: function (marketingTaskId) {
      return `/api/v1/marketing_tasks/${marketingTaskId}`;
    },
    method: DELETE_METHOD,
  },
  restoreMarketingTask: {
    getPath: function (marketingTaskId) {
      return `/api/v1/marketing_tasks/${marketingTaskId}/restore`;
    },
    method: PUT_METHOD,
  },
  deleteMarketingTaskImage: {
    getPath: function (marketingTaskImageId) {
      return `/api/v1/marketing_task_image_uploads/${marketingTaskImageId}`;
    },
    method: DELETE_METHOD,
  },
  updateMarketingTask: {
    getPath: function (marketingTaskId) {
      return `/api/v1/marketing_tasks/${marketingTaskId}`;
    },
    method: PUT_METHOD,
  },
  assignMarketingTaskToSelf: {
    getPath: function (marketingTaskId) {
      return `/api/v1/marketing_tasks/${marketingTaskId}/assign_user`;
    },
    method: PUT_METHOD,
  },
  saveResponse: {
    getPath: function (questionnaireId) {
      return '/questionnaires/' + questionnaireId + '/responses';
    },
    method: POST_METHOD,
  },
  staffMemberPayments: {
    getPath: function (staffMemberId, queryStringParams) {
      if (staffMemberId === undefined) {
        throw new Error('No staff member id supplied to appRoutes.staffMember');
      }
      const baseUrl = `/api/v1/staff_members/${staffMemberId}/payments`;

      if (typeof queryStringParams === 'undefined' || _.isEmpty(queryStringParams)) {
        return baseUrl;
      } else {
        let queryString = '?';
        let paramIndex = 0;
        for (const key in queryStringParams) {
          queryString = `${queryString}${paramIndex > 0 ? '&' : ''}${key}=${queryStringParams[key]}`;
          paramIndex = paramIndex + 1;
        }
        return baseUrl + queryString;
      }
    },
    method: GET_METHOD,
  },
  staffMemberProfileHolidaysIndex: {
    getPath: function (params) {
      const staffMemberId = oFetch(params, 'staffMemberId');
      const mStartDate = params.mStartDate;
      const mEndDate = params.mEndDate;
      const mPayslipStartDate = params.mPayslipStartDate;
      const mPayslipEndDate = params.mPayslipEndDate;
      const filteringByDate = mStartDate && mEndDate;
      const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

      let result = '/api/v1/staff_members/' + staffMemberId + '/holidays';
      if (filteringByDate || filteringByPayslipDate) {
        result = result + '?';
        if (filteringByDate) {
          result =
            result +
            'start_date=' +
            mStartDate.format(utils.apiDateFormat) +
            '&end_date=' +
            mEndDate.format(utils.apiDateFormat);
        }
        if (filteringByPayslipDate) {
          if (filteringByDate) {
            result = result + '&';
          }
          result =
            result +
            'payslip_start_date=' +
            mPayslipStartDate.format(utils.apiDateFormat) +
            '&payslip_end_date=' +
            mPayslipEndDate.format(utils.apiDateFormat);
        }
      }
      return result;
    },
    method: GET_METHOD,
  },
  mossStaffMemberProfileHolidaysIndex: {
    getPath: function (params) {
      const mossStaffMemberId = oFetch(params, 'mossStaffMemberId');
      const mStartDate = params.mStartDate;
      const mEndDate = params.mEndDate;
      const mPayslipStartDate = params.mPayslipStartDate;
      const mPayslipEndDate = params.mPayslipEndDate;
      const filteringByDate = mStartDate && mEndDate;
      const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

      let result = '/api/v1/moss_staff_members/' + mossStaffMemberId + '/holidays';
      if (filteringByDate || filteringByPayslipDate) {
        result = result + '?';
        if (filteringByDate) {
          result =
            result +
            'start_date=' +
            mStartDate.format(utils.apiDateFormat) +
            '&end_date=' +
            mEndDate.format(utils.apiDateFormat);
        }
        if (filteringByPayslipDate) {
          if (filteringByDate) {
            result = result + '&';
          }
          result =
            result +
            'payslip_start_date=' +
            mPayslipStartDate.format(utils.apiDateFormat) +
            '&payslip_end_date=' +
            mPayslipEndDate.format(utils.apiDateFormat);
        }
      }
      return result;
    },
    method: GET_METHOD,
  },
  staffMemberProfileUpdatePayslipDate: {
    getPath(options) {
      const accessoryRequestId = oFetch(options, 'accessoryRequestId');

      return `/api/v1/accessory-requests/${accessoryRequestId}/update_payslip_date`;
    },
    method: POST_METHOD,
  },
  staffMemberProfileCreateLinkedUserEmail: {
    getPath(options) {
      const staffMemberId = oFetch(options, 'staffMemberId');

      return `/api/v1/staff_members/${staffMemberId}/create_linked_user_email`;
    },
    method: POST_METHOD,
  },
  accessoryRequestUpdatePayslipDate: {
    getPath(options) {
      const accessoryRequestId = oFetch(options, 'accessoryRequestId');

      return `/api/v1/accessory-requests/${accessoryRequestId}/update_payslip_date`;
    },
    method: POST_METHOD,
  },
  accessoryRequestRefundUpdatePayslipDate: {
    getPath(options) {
      const accessoryRequestId = oFetch(options, 'accessoryRequestId');

      return `/api/v1/accessory-requests/${accessoryRequestId}/update_refund_payslip_date`;
    },
    method: POST_METHOD,
  },
  mossStaffMemberProfileOwedHoursIndex: {
    getPath: function (params) {
      const mossStaffMemberId = oFetch(params, 'mossStaffMemberId');
      const mStartDate = params.mStartDate;
      const mEndDate = params.mEndDate;
      const mPayslipStartDate = params.mPayslipStartDate;
      const mPayslipEndDate = params.mPayslipEndDate;
      const filteringByDate = mStartDate && mEndDate;
      const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

      let result = '/api/v1/moss_staff_members/' + mossStaffMemberId + '/owed_hours';
      if (filteringByDate || filteringByPayslipDate) {
        result = result + '?';
        if (filteringByDate) {
          result =
            result +
            'start_date=' +
            mStartDate.format(utils.apiDateFormat) +
            '&end_date=' +
            mEndDate.format(utils.apiDateFormat);
        }
        if (filteringByPayslipDate) {
          if (filteringByDate) {
            result = result + '&';
          }
          result =
            result +
            'payslip_start_date=' +
            mPayslipStartDate.format(utils.apiDateFormat) +
            '&payslip_end_date=' +
            mPayslipEndDate.format(utils.apiDateFormat);
        }
      }
      return result;
    },
    method: GET_METHOD,
  },
  staffMemberProfileOwedHoursIndex: {
    getPath: function (params) {
      const staffMemberId = oFetch(params, 'staffMemberId');
      const mStartDate = params.mStartDate;
      const mEndDate = params.mEndDate;
      const mPayslipStartDate = params.mPayslipStartDate;
      const mPayslipEndDate = params.mPayslipEndDate;
      const filteringByDate = mStartDate && mEndDate;
      const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

      let result = '/api/v1/staff_members/' + staffMemberId + '/owed_hours';
      if (filteringByDate || filteringByPayslipDate) {
        result = result + '?';
        if (filteringByDate) {
          result =
            result +
            'start_date=' +
            mStartDate.format(utils.apiDateFormat) +
            '&end_date=' +
            mEndDate.format(utils.apiDateFormat);
        }
        if (filteringByPayslipDate) {
          if (filteringByDate) {
            result = result + '&';
          }
          result =
            result +
            'payslip_start_date=' +
            mPayslipStartDate.format(utils.apiDateFormat) +
            '&payslip_end_date=' +
            mPayslipEndDate.format(utils.apiDateFormat);
        }
      }
      return result;
    },
    method: GET_METHOD,
  },
  staffMemberProfileAccessoriesIndex: {
    getPath: function (params) {
      const staffMemberId = oFetch(params, 'staffMemberId');
      const { mPayslipStartDate, mPayslipEndDate } = params;
      const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;
      let result = `/api/v1/staff_members/${staffMemberId}/accessory-requests`;
      if (filteringByPayslipDate) {
        result =
          result +
          '?' +
          'payslip_start_date=' +
          mPayslipStartDate.format(utils.apiDateFormat) +
          '&payslip_end_date=' +
          mPayslipEndDate.format(utils.apiDateFormat);
      }
      return result;
    },
    method: GET_METHOD,
  },
  addShift: {
    getPath: function (venueId, date) {
      return 'venues/' + venueId + '/rotas/' + utils.formatDateForApi(date) + '/rota_shifts';
    },
    method: POST_METHOD,
  },
  updateShift: {
    getPath: function (options) {
      return 'rota_shifts/' + options.shiftId;
    },
    method: PATCH_METHOD,
  },
  deleteShift: {
    getPath: function (options) {
      return 'rota_shifts/' + options.shiftId;
    },
    method: DELETE_METHOD,
  },
  updateRotaStatus: {
    getPath: function (options) {
      var [venueServerId, date, status] = oFetch(options, 'venueServerId', 'date', 'status');
      return 'venues/' + venueServerId + '/rotas/' + utils.formatDateForApi(date) + '/mark_' + status;
    },
    method: POST_METHOD,
  },
  publishRotas: {
    getPath: function (options) {
      var { venueId, date } = options;
      return ['/venues/' + venueId, '/rotas/publish/?', 'date=' + utils.formatDateForApi(date)].join('');
    },
    method: POST_METHOD,
  },
  weeklyRotaForecast: {
    getPath: function ({ venueId, startOfWeek }) {
      return ['venues/' + venueId, '/rota_forecasts/' + utils.formatDateForApi(startOfWeek), '/weekly'].join(
        '',
      );
    },
    method: GET_METHOD,
  },
  updateRotaForecast: {
    getPath: function ({ venueId, dateOfRota }) {
      return 'venues/' + venueId + '/rota_forecasts/' + utils.formatDateForApi(dateOfRota);
    },
    method: POST_METHOD,
  },
  updateStaffClockingStatus: {
    getPath: function ({ currentStatus, newStatus }) {
      var actionsByCurrentAndNewStatus = {
        clocked_out: {
          clocked_in: 'clock_in',
        },
        clocked_in: {
          clocked_out: 'clock_out',
          on_break: 'start_break',
        },
        on_break: {
          clocked_in: 'end_break',
          clocked_out: 'clock_out',
        },
      };
      return 'clocking/' + actionsByCurrentAndNewStatus[currentStatus][newStatus];
    },
    method: POST_METHOD,
  },
  getSessionToken: {
    getPath() {
      return 'sessions';
    },
    method: POST_METHOD,
  },
  changeStaffMemberPin: {
    getPath({ staffMemberServerId }) {
      return 'staff_members/' + staffMemberServerId + '/change_pin';
    },
    method: POST_METHOD,
  },
  getClockInOutAppData: {
    getPath() {
      return 'clock_in_clock_out';
    },
    method: GET_METHOD,
  },
  forceClockOut: {
    getPath() {
      return 'hours_acceptance_periods/clock_out';
    },
    method: POST_METHOD,
  },
  createHoursAccceptancePeriod: {
    getPath() {
      return 'hours_acceptance_periods';
    },
    method: POST_METHOD,
  },
  updateHoursAcceptancePeriod: {
    getPath(options) {
      return 'hours_acceptance_periods/' + oFetch(options, 'hoursAcceptancePeriodServerId');
    },
    method: PUT_METHOD,
  },
  deleteHoursAcceptancePeriod: {
    getPath(options) {
      return 'hours_acceptance_periods/' + oFetch(options, 'hoursAcceptancePeriodServerId');
    },
    method: DELETE_METHOD,
  },
  addClockInNote: {
    getPath() {
      return 'clocking/add_note';
    },
    method: POST_METHOD,
  },
  getRotaWeeklyDay: {
    getPath(options) {
      return (
        'rota_weekly_day_data?date=' +
        oFetch(options, 'date') +
        '&venue_id=' +
        oFetch(options, 'serverVenueId')
      );
    },
    method: GET_METHOD,
  },
  createVenueAlert: {
    getPath() {
      return '/api/v1/venue_alerts';
    },
    method: POST_METHOD,
  },
  updateVenueAlert: {
    getPath(alertId) {
      return `/api/v1/venue_alerts/${alertId}`;
    },
    method: PUT_METHOD,
  },
  dismissVenueAlert: {
    getPath(alertId) {
      return `/api/v1/venue_alerts/${alertId}/dismiss`;
    },
    method: POST_METHOD,
  },
  enableVenueAlert: {
    getPath(alertId) {
      return `/api/v1/venue_alerts/${alertId}/enable`;
    },
    method: POST_METHOD,
  },
  allowClockingWithoutFacialRecognition: {
    getPath(staffMemberId) {
      return `/api/v1/staff_members/${staffMemberId}/allow_clocking_without_facial_recognition`;
    },
    method: POST_METHOD,
  },
  disallowClockingWithoutFacialRecognition: {
    getPath(staffMemberId) {
      return `/api/v1/staff_members/${staffMemberId}/disallow_clocking_without_facial_recognition`;
    },
    method: POST_METHOD,
  },
  createHandoverPlannerTask: {
    getPath() {
      return `/api/v1/handover_planner_tasks`;
    },
    method: POST_METHOD,
  },
  editHandoverPlannerTask: {
    getPath(id) {
      return `/api/v1/handover_planner_tasks/${id}`;
    },
    method: PUT_METHOD,
  },
  deleteHandoverPlannerTask: {
    getPath(id) {
      return `/api/v1/handover_planner_tasks/${id}`;
    },
    method: DELETE_METHOD,
  },
  declineHandoverPlannerTask: {
    getPath(id) {
      return `/api/v1/handover_planner_tasks/${id}/decline`;
    },
    method: POST_METHOD,
  },
  postponeHandoverPlannerTask: {
    getPath(id) {
      return `/api/v1/handover_planner_tasks/${id}/postpone`;
    },
    method: POST_METHOD,
  },
  createHandoverPlannerEvent: {
    getPath() {
      return `/api/v1/handover_planner_events`;
    },
    method: POST_METHOD,
  },
  editHandoverPlannerEvent: {
    getPath(id) {
      return `/api/v1/handover_planner_events/${id}`;
    },
    method: PUT_METHOD,
  },
  deleteHandoverPlannerEvent: {
    getPath(id) {
      return `/api/v1/handover_planner_events/${id}`;
    },
    method: DELETE_METHOD,
  },
  createHandoverPlannerReminder: {
    getPath() {
      return `/api/v1/handover_planner_reminders`;
    },
    method: POST_METHOD,
  },
  getMisattributedLogins: {
    getPath() {
      return `/api/v1/misattributed_logins`;
    },
    method: GET_METHOD,
  },
  markMisattributedLoginHandled: {
    getPath(loginId) {
      return `/api/v1/misattributed_logins/${loginId}/mark-handled`;
    },
    method: POST_METHOD,
  },
  editHandoverPlannerReminder: {
    getPath(id) {
      return `/api/v1/handover_planner_reminders/${id}`;
    },
    method: PUT_METHOD,
  },
  deleteHandoverPlannerReminder: {
    getPath(id) {
      return `/api/v1/handover_planner_reminders/${id}`;
    },
    method: DELETE_METHOD,
  },
  deleteRecurringFutureHandoverPlannerReminders: {
    getPath(id) {
      return `/api/v1/handover_planner_reminders/${id}/delete_future`;
    },
    method: DELETE_METHOD,
  },
  deleteRecurringSingleHandoverPlannerReminders: {
    getPath(id) {
      return `/api/v1/handover_planner_reminders/${id}/delete_single`;
    },
    method: DELETE_METHOD,
  },
  deleteRecurringAllHandoverPlannerReminders: {
    getPath(id) {
      return `/api/v1/handover_planner_reminders/${id}/delete_all`;
    },
    method: DELETE_METHOD,
  },
  completeHandoverPlannerReminder: {
    getPath(id) {
      return `/api/v1/handover_planner_reminders/${id}/complete`;
    },
    method: POST_METHOD,
  },
  activateHandoverPlannerReminder: {
    getPath(id) {
      return `/api/v1/handover_planner_reminders/${id}/activate`;
    },
    method: POST_METHOD,
  },
  completeHandoverPlannerTask: {
    getPath(id) {
      return `/api/v1/handover_planner_tasks/${id}/complete`;
    },
    method: POST_METHOD,
  },
  activateHandoverPlannerTask: {
    getPath(id) {
      return `/api/v1/handover_planner_tasks/${id}/activate`;
    },
    method: POST_METHOD,
  },
  handoverPlannerSort: {
    getPath() {
      return `/api/v1/handover_planner/sort`;
    },
    method: POST_METHOD,
  },
  staffMemberGeneralFieldsValidation: {
    getPath() {
      return `/api/v1/staff_members/general_fields_validation`;
    },
    method: POST_METHOD,
  },
  createStaffMember: {
    getPath() {
      return `/api/v1/staff_members`;
    },
    method: POST_METHOD,
  },
  mossStaffMemberProfileMossOwedHoursIndex: {
    getPath: function (params) {
      const mossStaffMemberId = oFetch(params, 'mossStaffMemberId');
      const mStartDate = params.mStartDate;
      const mEndDate = params.mEndDate;
      const mPayslipStartDate = params.mPayslipStartDate;
      const mPayslipEndDate = params.mPayslipEndDate;
      const filteringByDate = mStartDate && mEndDate;
      const filteringByPayslipDate = mPayslipStartDate && mPayslipEndDate;

      let result = '/api/v1/moss_staff_members/' + mossStaffMemberId + '/moss_owed_hours';
      if (filteringByDate || filteringByPayslipDate) {
        result = result + '?';
        if (filteringByDate) {
          result =
            result +
            'start_date=' +
            mStartDate.format(utils.apiDateFormat) +
            '&end_date=' +
            mEndDate.format(utils.apiDateFormat);
        }
        if (filteringByPayslipDate) {
          if (filteringByDate) {
            result = result + '&';
          }
          result =
            result +
            'payslip_start_date=' +
            mPayslipStartDate.format(utils.apiDateFormat) +
            '&payslip_end_date=' +
            mPayslipEndDate.format(utils.apiDateFormat);
        }
      }
      return result;
    },
    method: GET_METHOD,
  },
  venueAccessories: {
    getPath() {
      return `/api/v1/accessories/venue_accessories`;
    },
    method: GET_METHOD,
  },
  withoutScannableAvatars: {
    getPath() {
      return `/api/v1/staff_vetting/without-scannable-avatars`;
    },
    method: GET_METHOD,
  },
  createQuizQuestion: {
    getPath() {
      return `/api/v1/quiz_questions`;
    },
    method: POST_METHOD,
  },
  updateQuizQuestion: {
    getPath(questionId) {
      return `/api/v1/quiz_questions/${questionId}`;
    },
    method: PUT_METHOD,
  },
  moveQuizQuestion: {
    getPath: options => {
      const quizCategoryQuestionId = oFetch(options, 'quizCategoryQuestionId');
      return `/api/v1/quiz_questions/${quizCategoryQuestionId}/move`;
    },
    method: POST_METHOD,
  },
  disableQuizQuestion: {
    getPath(questionId) {
      return `/api/v1/quiz_questions/${questionId}/disable`;
    },
    method: POST_METHOD,
  },
  enableQuizQuestion: {
    getPath(questionId) {
      return `/api/v1/quiz_questions/${questionId}/enable`;
    },
    method: POST_METHOD,
  },
  createBonaiEligibility: {
    getPath() {
      return `/api/v1/bonai/create_eligibility_override`;
    },
    method: POST_METHOD,
  },
  neutraliseEligibilityOverride: {
    getPath() {
      return `/api/v1/bonai/neutralise_eligibility_override`;
    },
    method: POST_METHOD,
  },
  updateVenueBonaiCents: {
    getPath() {
      return `/api/v1/bonai/update_bonai_cents`;
    },
    method: POST_METHOD,
  },
  createForStaffMembersSecurityPayRate: {
    getPath() {
      return `/api/v1/security_pay_rates/create_for_security_staff`;
    },
    method: POST_METHOD,
  },
  createForSpecialSecurityHoursSecurityPayRate: {
    getPath() {
      return `/api/v1/security_pay_rates/create_for_special_security_hours`;
    },
    method: POST_METHOD,
  },
  updateForStaffMembersSecurityPayRate: {
    getPath(id) {
      return `/api/v1/security_pay_rates/${id}/update_for_security_staff`;
    },
    method: PUT_METHOD,
  },
  updateForSpecialSecurityHoursSecurityPayRate: {
    getPath(id) {
      return `/api/v1/security_pay_rates/${id}/update_for_special_security_hours`;
    },
    method: PUT_METHOD,
  },
  updateForSpecialSecurityHoursSecurityPayRateVenuesAssignments: {
    getPath(id) {
      return `/api/v1/security_pay_rates/${id}/update_special_security_hour_venue_assignments`;
    },
    method: PUT_METHOD,
  },
  createSecurityPayRateException: {
    getPath(id) {
      return `/api/v1/security_pay_rates/${id}/create_exception`;
    },
    method: POST_METHOD,
  },
  updateSecurityPayRateException: {
    getPath(id) {
      return `/api/v1/security_pay_rates/${id}/update_exception`;
    },
    method: POST_METHOD,
  },
  deleteSecurityPayRateException: {
    getPath(id) {
      return `/api/v1/security_pay_rates/${id}/delete_exception`;
    },
    method: POST_METHOD,
  },
  finishFinanceReportWeek: {
    getPath() {
      return `/api/v1/finance_reports/finish_finance_report_week`;
    },
    method: POST_METHOD,
  },
  finishMossFinanceReportWeek: {
    getPath() {
      return `/api/v1/moss_finance_reports/finish_moss_finance_report_week`;
    },
    method: POST_METHOD,
  },
  undoFinanceReportWeek: {
    getPath() {
      return `/api/v1/finance_reports/undo_finance_report_week`;
    },
    method: POST_METHOD,
  },
  undoMossFinanceReportWeek: {
    getPath() {
      return `/api/v1/moss_finance_reports/unfinish_moss_finance_report_week`;
    },
    method: POST_METHOD,
  },
  createSiteIssue: {
    getPath() {
      return `/api/v1/site_issues`;
    },
    method: POST_METHOD,
  },
  updateSiteIssue: {
    getPath(id) {
      return `/api/v1/site_issues/${id}`;
    },
    method: POST_METHOD,
  },
  activateSiteIssue: {
    getPath(id) {
      return `/api/v1/site_issues/${id}/activate`;
    },
    method: POST_METHOD,
  },
  setPendingSiteIssue: {
    getPath(id) {
      return `/api/v1/site_issues/${id}/set_pending`;
    },
    method: POST_METHOD,
  },
  disableSiteIssue: {
    getPath(id) {
      return `/api/v1/site_issues/${id}`;
    },
    method: DELETE_METHOD,
  },
  createBonusPayment: {
    getPath() {
      return `/api/v1/bonus_payments_admin`;
    },
    method: POST_METHOD,
  },
  updateBonusPayment: {
    getPath(id) {
      return `/api/v1/bonus_payments_admin/${id}`;
    },
    method: PUT_METHOD,
  },
  disableBonusPayment: {
    getPath(id) {
      return `/api/v1/bonus_payments_admin/${id}`;
    },
    method: DELETE_METHOD,
  },
  createTemporaryAccess: {
    getPath(userId) {
      return `/api/v1/users/${userId}/temporary_permissions`;
    },
    method: 'post',
  },
  updateTemporaryAccess: {
    getPath(userId, temporaryPermissionId) {
      return `/api/v1/users/${userId}/temporary_permissions/${temporaryPermissionId}`;
    },
    method: 'put',
  },
  disableTemporaryAccess: {
    getPath(userId, temporaryPermissionId) {
      return `/api/v1/users/${userId}/temporary_permissions/${temporaryPermissionId}`;
    },
    method: 'delete',
  },
  disableAppPermissions: {
    getPath(params) {
      const [userId, jsmBarsApplicationId] = oFetch(params, 'userId', 'jsmBarsApplicationId');
      return `/api/v1/users/${userId}/application_access/${jsmBarsApplicationId}`;
    },
    method: 'post',
  },
  editUserRole: {
    getPath(userId) {
      return `/api/v1/users/${userId}/edit_role`;
    },
    method: 'post',
  },
  updatePermanentAccess: {
    getPath(userId) {
      return `/api/v1/users/${userId}/permanent_permissions`;
    },
    method: 'put',
  },
  updateUserPersonalDetails: {
    getPath(userId) {
      return `/api/v1/users/${userId}/update_personal_details`;
    },
    method: PUT_METHOD,
  },
  updateUserAccessDetails: {
    getPath(userId) {
      return `/api/v1/users/${userId}/update_access_details`;
    },
    method: PUT_METHOD,
  },
  disableUser: {
    getPath(userId) {
      return `/api/v1/users/${userId}`;
    },
    method: DELETE_METHOD,
  },
  enableUser: {
    getPath(userId) {
      return `/api/v1/users/${userId}/enable`;
    },
    method: POST_METHOD,
  },
  createLinkedInvite: {
    getPath(staffMemberId) {
      return `/api/v1/staff_members/${staffMemberId}/invites`;
    },
    method: POST_METHOD,
  },
};

export { apiRoutes };

export function getApiRoot() {
  var apiRootPrefix = '';
  if (window.apiRootPrefix) {
    apiRootPrefix = window.apiRootPrefix;
  }
  return apiRootPrefix + '/api/v1/';
}
