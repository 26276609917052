import React from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { NULL_DATE_FORMAT, SHORT_TIME_FORMAT } from '@/lib/date-fns-formats';
import { bossDateFnsFormat } from '@/lib/date-format-funcs';
import { minutesToFormattedHoursAndMinutes } from '@/lib/format-funcs';

export function HoursOverviewOwedHoursSection(props) {
  const owedHours = oFetch(props, 'owedHours');
  const owedHoursTotalMinutes = owedHours.reduce((acc, owedHour) => acc + oFetch(owedHour, 'minutes'), 0);
  const [showOwedHourDetails, setShowOwedHourDetails] = React.useState(false);

  function renderOwedHours() {
    return (
      <ul>
        {owedHours.map(renderOwedHour)}
      </ul>
    );
  }

  function renderMossHourTag(mossHourTag) {
    const id = oFetch(mossHourTag, 'id');
    const name = oFetch(mossHourTag, 'name');

    return (
      <li className="boss-tag boss-tag_size_xxs boss-tag_role_label boss-tag_role_hours-tag" key={id}>
        <a className="boss-tag__text">{name}</a>
      </li>
    );
  }

  function renderOwedHour(owedHour) {
    const id = oFetch(owedHour, 'id');
    const minutes = oFetch(owedHour, 'minutes');
    const dStartsAt = safeMoment.iso8601Parse(oFetch(owedHour, 'startsAt')).toDate();
    const dEndsAt = safeMoment.iso8601Parse(oFetch(owedHour, 'endsAt')).toDate();
    const createdBy = oFetch(owedHour, 'createdBy');
    const dCreatedAt = safeMoment.iso8601Parse(
      oFetch(owedHour, 'createdAt')
    ).toDate();
    const mossHourTags = oFetch(owedHour, 'mossHourTags');
    const note = oFetch(owedHour, 'note');
    const detailsProfileUrl = oFetch(owedHour, 'detailsProfileUrl');
    const formattedLength = minutesToFormattedHoursAndMinutes(minutes);

    return (
      <div
        key={id}
        style={{ marginTop: "1em" }}
      >
        <div>
          <b style={{ fontSize: '1.2em' }}>
            {`${bossDateFnsFormat({ dDate: dStartsAt, dateFnsDateFormat: NULL_DATE_FORMAT, dateFnsTimeFormat: SHORT_TIME_FORMAT, showTimeAsterisk: true })}-${bossDateFnsFormat({ dDate: dEndsAt, dateFnsDateFormat: NULL_DATE_FORMAT, dateFnsTimeFormat: SHORT_TIME_FORMAT, showTimeAsterisk: true })} (${formattedLength})`}
          </b>
          <span> created by {createdBy} at {bossDateFnsFormat({ dDate: dCreatedAt, dateFnsDateFormat: NULL_DATE_FORMAT, dateFnsTimeFormat: SHORT_TIME_FORMAT, showTimeAsterisk: true })} </span>
          <a href={detailsProfileUrl}>view</a>
        </div>
        <div>
          <div>
            <span>Tags: </span>
            { oFetch(mossHourTags, 'length') === 0 && <span>N/A</span> }
            { oFetch(mossHourTags, 'length') > 0 && (
              <ul style={{ display: 'inline' }}>
                {mossHourTags.map(renderMossHourTag)}
              </ul>
            )}
          </div>
          { note && !(note == "") && <div>Note: {note}</div> }
        </div>
      </div>
    );
  }

  const owedHourShowClass = showOwedHourDetails ? 'block' : 'none';
  const showHideButtonLabel = showOwedHourDetails ? 'Hide' : 'Show Details';

  function toggleShowHide(e) {
    e.preventDefault();

    setShowOwedHourDetails(previous => !previous);
  }

  return (
    <div
      className="boss-page-main__inner boss-page-main__inner_space_regular boss-page-main__inner_opaque"
      style={{ marginBottom: "2em" }}
    >
      <div>
        <h4 style={{ display: "inline" }}>{`${minutesToFormattedHoursAndMinutes(owedHoursTotalMinutes)} Owed Hours`} </h4>
        <a
          target="_blank"
          onClick={toggleShowHide}
        >{ showHideButtonLabel }</a>
      </div>
      <div style={{ display: owedHourShowClass, marginTop: "1em" }}>
        { renderOwedHours() }
      </div>
    </div>
  );
}